import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LoggerService } from '@services/logging/logger.service';
import { messageContext } from '@shared/constants';

@Injectable({
  providedIn: 'root',
})
export class MessageHandlerService {
  constructor(private logger: LoggerService, private router: Router, private translateService: TranslateService) {}
  context: string = '';
  moduleName: string = 'MessageHandlerService';
  errorMessage: string = '';
  handleServerError(error: any, origin: string, method: string): void {
    const errorMessage = error?.message ?? error;
    if (error?.error?.messageId) this.errorMessage = this.translateService.instant(error?.error?.messageId);
    else this.errorMessage = '';
    this.logger.error(
      `Server error! ${errorMessage} occured in method: ${method}`,
      { module: this.moduleName }
    );
    this.logger.debug(
      `Navigating from /${origin} to /message page with context: error`,
      { module: this.moduleName }
    );
    this.router.navigate(['/message'], {
      queryParams: { context: messageContext.error },
    });
  }
}
